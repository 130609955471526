import Glide, {Controls, Swipe} from '@glidejs/glide/dist/glide.modular.esm'
import Contact from './components/contact';
import EncodeThreeScene from "./components/encode-three-scene";
import gsap from "gsap";
import 'normalize.css/normalize.css';
import './styles.scss';
//import './styles-dark.scss';


if (window.location.pathname == "/w" || window.location.pathname == "/plw")
{
    //import('./styles.scss');
}
else
{
    import('./styles-dark.scss');
}

new Glide('.glide').mount({Controls, Swipe});
new Contact();

Math.degToRad = degrees => degrees * (Math.PI / 180);

window.routerLoadedEvent = new Event('RouterLoadedEvent');

function calculateModelSpancerHeight() {
    const goToContactButton = document.getElementById('end-of-mission-line');
    const goToContactButtonRect = goToContactButton.getBoundingClientRect();
    document.getElementById('model-spancer').style.height = goToContactButtonRect.top + goToContactButtonRect.height + window.scrollY + 'px';
}

document.addEventListener('DOMContentLoaded', (event) => {
    new EncodeThreeScene();
});

const progressLoadingValue = document.getElementById('loading-progress-value');
const loadingScreen = document.getElementById('loading');

document.addEventListener('LoadingProgressEvent', (event) => {
    const detail = event.detail;
    let percent = detail.loaded * 100 / detail.total;
    percent = percent > 100 ? 100 : percent;
    progressLoadingValue.innerText = Math.round(percent).toString();
});

document.addEventListener('RouterLoadedEvent', () => {
    setTimeout(() => {
        calculateModelSpancerHeight();
    });

    setTimeout(() => {
        animatePointers();
    }, 400);

    setTimeout(() => {
        loadingScreen.style.opacity = '0';
        document.body.style.overflow = null;
    }, 500);

    setTimeout(() => {
        loadingScreen.style.display = 'none';
    }, 1000);
});

window.mobileCheck = function () {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const pointer1 = document.getElementById('pointer1');
const pointer1point = pointer1.querySelector('.point');
const pointer1line = document.getElementById('pointer1line');

const pointer2 = document.getElementById('pointer2');
const pointer2point = pointer2.querySelector('.point');
const pointer2line = document.getElementById('pointer2line');

const pointer3 = document.getElementById('pointer3');
const pointer3point = pointer3.querySelector('.point');
const pointer3line = document.getElementById('pointer3line');

function animatePointers() {
    setTimeout(() => {
        pointer1point.style.opacity = 0;
        pointer2point.style.opacity = 0;
        pointer3point.style.opacity = 0;
    });

    handlePointer1();
    handlePointer2();
    handlePointer3();

    gsap.timeline({
        scrollTrigger: {
            trigger: "#section-second",
            start: "-35% top",
            end: "15% top",
            scrub: true,
            toggleActions: 'play pause resume reset'
        }
    }).fromTo(pointer1point, {
        opacity: 0,
    }, {
        opacity: 1,
    })

    gsap.timeline({
        scrollTrigger: {
            trigger: "#section-second",
            start: "top top",
            end: "30% top",
            scrub: true,
            toggleActions: 'play pause resume reset'
        }
    }).fromTo(pointer1point, {
        opacity: 1
    }, {
        opacity: 0
    });

    gsap.timeline({
        immediateRender: false,
        scrollTrigger: {
            trigger: "#section-second",
            start: "40% top",
            end: "bottom top",
            scrub: true,
            toggleActions: 'play pause resume reset'
        }
    }).fromTo(pointer2point, {
        opacity: 0,
    }, {
        opacity: 1,
    })


    gsap.timeline({
        immediateRender: false,
        scrollTrigger: {
            trigger: "#section-second",
            start: "50% top",
            end: "bottom top",
            scrub: true,
            toggleActions: 'play pause resume reset'
        }
    }).fromTo(pointer3point, {
        opacity: 0,
    }, {
        opacity: 1,
    })

    gsap.timeline({
        immediateRender: false,
        scrollTrigger: {
            trigger: "#section-third",
            start: "top top",
            end: "5% top",
            scrub: true,
            toggleActions: 'play pause resume reset'
        }
    }).fromTo(pointer2point, {
        opacity: 1,
    }, {
        opacity: 0,
    }).fromTo(pointer3point, {
        opacity: 1,
    }, {
        opacity: 0,
    });
}

function handlePointer1() {
    if (checkIsMobileResolution()) {
        return;
    }

    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    pointer1point.style.left = `${clientWidth / 3}px`;
    pointer1point.style.top = `${clientHeight / 3}px`;

    const p1 = getElementCoordinates(pointer1point);
    const p2 = getElementCoordinates(pointer1);

    const lineAngle = calculateAngle(p1, p2);
    pointer1line.style.transform = 'rotate(' + (lineAngle * -1) + 'deg)';
}

function handlePointer2() {
    if (checkIsMobileResolution()) {
        return;
    }

    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    pointer2point.style.left = `${clientWidth / 4}px`;
    pointer2point.style.top = `${clientHeight / 4}px`;

    const p1 = getElementCoordinates(pointer2point);
    const p2 = getElementCoordinates(pointer2);

    const lineAngle = calculateAngle(p1, p2);
    pointer2line.style.transform = 'rotate(' + (lineAngle * -1) + 'deg)';
}

function handlePointer3() {
    if (checkIsMobileResolution()) {
        return;
    }

    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    pointer3point.style.left = `${clientWidth / 4}px`;
    pointer3point.style.bottom = `${clientHeight / 4}px`;

    const p1 = getElementCoordinates(pointer3point);
    const p2 = getElementCoordinates(pointer3);

    const lineAngle = calculateAngle(p1, p2);
    pointer3line.style.transform = 'rotate(' + (lineAngle * -1) + 'deg)';
}

function calculateAngle(p1, p2) {
    return 90 - Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
}

function getElementCoordinates(element) {
    return {
        x: element.getBoundingClientRect().left + window.scrollX,
        y: element.getBoundingClientRect().top + window.scrollY
    }
}

function checkIsMobileResolution() {
    return window.innerWidth < 992;
}

window.addEventListener('resize', handlePointer1);
window.addEventListener('scroll', handlePointer1);

window.addEventListener('resize', handlePointer2);
window.addEventListener('scroll', handlePointer2);

window.addEventListener('resize', handlePointer3);
window.addEventListener('scroll', handlePointer3);