export default class Contact {
    isFormLoading = false;
    formControls = this.getFormControls();

    constructor() {
        this.setupListeners();
    }


    setupListeners() {
        document.getElementById('form-contact').addEventListener('submit', this.onFormSubmit.bind(this));
        document.getElementById('go-to-contact-button').addEventListener('click', this.onGoToContactClick.bind(this))
        document.getElementById('send-another-message').addEventListener('click', this.sendAnotherMessage.bind(this))

        this.formControls.name.addEventListener('keydown', () => this.onFormControlChange(this.formControls.name));
        this.formControls.mail.addEventListener('keydown', () => this.onFormControlChange(this.formControls.mail));
        this.formControls.message.addEventListener('keydown', () => this.onFormControlChange(this.formControls.message));
        this.formControls.checkbox.addEventListener('keydown', () => this.onFormControlChange(this.formControls.checkbox));
    }

    onFormSubmit(e) {
        e.preventDefault();

        if (!this.isFormValid() || this.isFormLoading) {
            return;
        }

        this.startFormLoading();

        document.getElementById('form-loading').style.display = 'flex';

        grecaptcha.ready(() => {
            grecaptcha.execute('6LdLNYYeAAAAAO_CNzoFgmDy1uNOFk655mf-UUV5', {action: 'submit'})
                .then(() => {
                    return this.getFormControlValues(this.formControls);
                })
                .then((data) => this.sendMailTo(data))
                .then(() => this.sendSuccess());
        });
    }


    sendMailTo({message, name, mail}) {
        const destinationMail = 'contact@n-codebox.com'
        const subject = `En - Code - Contact Form`
        const messageBody = `My name: ${name} \n \n My mail: ${mail} \n \n My message: \n \n ${message} \n`;
        window.location.href = `mailto:${destinationMail}?subject=${subject}&body=${encodeURIComponent(messageBody)}`;
    }

    sendSuccess() {
        document.getElementById('form-success').style.display = 'block';
        document.getElementById('form-loading').style.display = 'none';
        this.completeFormLoading();
    }

    onGoToContactClick(e) {
        e.preventDefault();
        document.getElementById('section-contact').scrollIntoView({behavior: 'smooth'});
    }

    sendAnotherMessage(e) {
        e.preventDefault();

        this.removeFormErrors();
        this.resetFormValues();

        document.getElementById('form-success').style.display = 'none';
    }

    dismissError(e) {
        e.preventDefault();
        document.getElementById('form-error').style.display = 'none';
    }

    getFormControlValues() {
        return {
            name: this.formControls.name.value,
            mail: this.formControls.mail.value,
            message: this.formControls.message.value,
            checkbox: this.formControls.checkbox.checked
        };
    }

    getFormControls() {
        return {
            name: document.getElementById('name'),
            mail: document.getElementById('mail'),
            message: document.getElementById('message'),
            checkbox: document.getElementById('checkbox')
        };
    }

    onFormControlChange(formControl) {
        formControl.classList.remove('form-control-error');
        this.setErrorMessage(formControl, '');
    }

    isFormValid() {
        const formValues = this.getFormControlValues(this.formControls);
        let isFormValid = true;

        this.removeFormErrors();

        if (!formValues.name || !formValues.name.length) {
            if (isFormValid) {
                this.formControls.name.focus();
            }

            isFormValid = false;
            this.formControls.name.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.name, 'Please provide your name');
        }

        if (!formValues.mail || !formValues.mail.length) {
            if (isFormValid) {
                this.formControls.mail.focus();
            }

            isFormValid = false;
            this.formControls.mail.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.mail, 'Please provide your email address');
        }

        if (formValues.mail && formValues.mail.length && !this.validateEmail(formValues.mail)) {
            if (isFormValid) {
                this.formControls.mail.focus();
            }

            isFormValid = false;
            this.formControls.mail.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.mail, 'Provided email address is incorrect');
        }

        if (!formValues.message || !formValues.message.length) {
            if (isFormValid) {
                this.formControls.message.focus();
            }

            isFormValid = false;
            this.formControls.message.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.message, 'Please provide message');
        }

        if (formValues.message && formValues.message.length > 2000) {
            if (isFormValid) {
                this.formControls.message.focus();
            }

            isFormValid = false;
            this.formControls.message.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.message, 'Provided message is too long. Maximum length is 2000 characters.');
        }

        if (!formValues.checkbox) {
            if (isFormValid) {
                this.formControls.checkbox.focus();
            }

            isFormValid = false;
            this.formControls.checkbox.classList.add('form-control-error');
            this.setErrorMessage(this.formControls.checkbox, 'This checkbox is required');
        }

        return isFormValid;
    }

    removeFormErrors() {
        Object.keys(this.formControls).forEach(key => {
            this.formControls[key].classList.remove('form-control-error');
            this.setErrorMessage(this.formControls[key], '');
        });
    }

    resetFormValues() {
        Object.keys(this.formControls).forEach(key => {
            this.formControls[key].value = '';
        });

        this.formControls.checkbox.checked = false;
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    setErrorMessage(formControl, message) {
        let parentElement = formControl.parentElement;

        if (!parentElement.classList.contains('form-control')) {
            parentElement = parentElement.parentElement;
        }

        const errorMessageElement = parentElement.querySelector('.error-message');

        errorMessageElement.innerText = message;
    }

    startFormLoading() {
        this.isFormLoading = true;
        this.disableFormControls();
    }

    completeFormLoading() {
        this.isFormLoading = false;
        this.enableFormControls();
    }

    disableFormControls() {
        Object.keys(this.formControls, (key) => {
            this.formControls[key].disabled = true;
        });
    }

    enableFormControls() {
        Object.keys(this.formControls, (key) => {
            this.formControls[key].removeAttribute('disabled');
        });
    }
}
