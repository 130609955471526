import {Color, Fog, PerspectiveCamera, Scene, Vector3, WebGLRenderer, sRGBEncoding} from "three";
import SeedScene from "./objects/Scene";
import Router from './objects/Router/Router';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

Math.degToRad = degrees => degrees * (Math.PI / 180);

export default class EncodeThreeScene {
    isMobileResolution;
    canvas = document.createElement('canvas');
    renderer = new WebGLRenderer({
        canvas: this.canvas,
        alpha: true,
        antialias: true
    });
    sceneElements = []
    clearColor = new Color('#ffffff');

    constructor() {
        this.isMobileResolution = this.checkIsMobileResolution();
        this.renderer.setClearColor(this.clearColor, 0);

        this.renderer.outputEncoding = sRGBEncoding;

        this.main();

        window.addEventListener('resize', () => {
            const isMobileResolution = this.checkIsMobileResolution();

            if (isMobileResolution !== this.isMobileResolution) {
                this.isMobileResolution = this.checkIsMobileResolution();
                this.main();
            }
        });
    }

    main() {
        const sceneInitFunctionsByName = {
            'router-mobile-1': (elem) => {
                return this.createScene(elem, this.routerMobile1Animation);
            },
            'router-mobile-2': (elem) => {
                return this.createScene(elem, this.routerMobile2Animation);
            },
            'router-1': (elem) => {
                return this.createScene(elem, this.router1Animation);
            },
            'router-2': (elem) => {
                return this.createScene(elem, this.router2Animation);
            }
        };

        document.querySelectorAll('[data-diagram]').forEach((elem) => {
            if ((elem.children && elem.children.length && elem.children[0].nodeName === 'CANVAS') ||
                (elem.dataset.diagram === 'router-1' && this.isMobileResolution) ||
                ((elem.dataset.diagram === 'router-mobile-1' || elem.dataset.diagram === 'router-mobile-2') && !this.isMobileResolution)) {
                return;
            }

            const sceneName = elem.dataset.diagram;
            const sceneInitFunction = sceneInitFunctionsByName[sceneName];
            const sceneRenderFunction = sceneInitFunction(elem);
            this.addScene(elem, sceneRenderFunction);
        });

        requestAnimationFrame(this.render.bind(this));
    }

    setupScene(scene, seedScene) {
        scene.add(seedScene);
        //scene.fog = new Fog(0xf2f2f2, 0.0025, 50);
    }

    getRouterObject(seedScene) {
        return seedScene.children.find((children) => children instanceof Router);
    }

    addScene(elem, fn) {
        const ctx = document.createElement('canvas').getContext('2d');
        elem.appendChild(ctx.canvas);
        this.sceneElements.push({elem, ctx, fn});
    }

    makeScene() {
        const scene = new Scene();

        const fov = 50;
        const aspect = 2;  // the canvas default
        const near = 0.1;
        const far = 20;
        const camera = new PerspectiveCamera(fov, aspect, near, far);

        camera.position.set(0, .4, -10);
        camera.lookAt(new Vector3(0, .4, 0));
        scene.add(camera);

        return {scene, camera};
    }

    render(time) {
        time *= 0.001;

        for (const {elem, fn, ctx} of this.sceneElements) {
            // get the viewport relative position opf this element
            const rect = elem.getBoundingClientRect();
            let {left, right, top, bottom, width, height} = rect;
            const pixelRatio = window.devicePixelRatio ? Math.min(window.devicePixelRatio, 1) : 1;

            if (this.isMobileResolution)
            {
                width = width*3 * pixelRatio | 0;
                height = height*3 * pixelRatio | 0;
            }
            else
            {
                width = width * pixelRatio | 0;
                height = height * pixelRatio | 0;
            }


            const rendererCanvas = this.renderer.domElement;

            const isOffscreen = bottom < 0 || top > window.innerHeight + 500 || right < 0 || left > window.innerWidth;

            if (!isOffscreen) {
                // make sure the renderer's canvas is big enough
                if (rendererCanvas.width < width || rendererCanvas.height < height) {
                    this.renderer.setSize(width, height, false);
                }

                // make sure the canvas for this area is the same size as the area
                if (ctx.canvas.width !== width || ctx.canvas.height !== height) {
                    ctx.canvas.width = width;
                    ctx.canvas.height = height;
                }

                this.renderer.setScissor(0, 0, width, height);
                this.renderer.setViewport(0, 0, width, height);

                fn(time, rect);

                // copy the rendered scene to this element's canvas
                ctx.globalCompositeOperation = 'copy';
                ctx.drawImage(
                    rendererCanvas,
                    0, rendererCanvas.height - height, width, height,  // src rect
                    0, 0, width, height);                              // dst rect
            }
        }

        requestAnimationFrame(this.render.bind(this));
    }

    createScene(elem, animationFunction) {
        const {scene, camera} = this.makeScene(elem);

        const seedScene = new SeedScene(this.renderer);

        this.setupScene(scene, seedScene);
        const router = this.getRouterObject(seedScene);

        setTimeout(() => {
            animationFunction(router);
        }, 400);

        return (time, rect) => {
            camera.aspect = rect.width / rect.height;
            camera.updateProjectionMatrix();
            this.renderer.render(scene, camera);
        };
    }

    routerMobile1Animation(router) {
        const router1InitialRotation = {
            x: Math.degToRad(0),
            y: Math.degToRad(0),
            z: Math.degToRad(0),
        }

        gsap.timeline({
            scrollTrigger: {
                trigger: '#section-landing',
                start: 'top top',
                end: '200% top',
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        })
            .add('start')
            .fromTo(router.rotation,
                {x: router1InitialRotation.x, y: router1InitialRotation.y, z: router1InitialRotation.z},
                {x: Math.degToRad(5), y: Math.degToRad(-55), z: Math.degToRad(5)},
                'start').fromTo(router.scale,
            {x: 1, y: 1, z: 1},
            {x: 0.8, y: 0.8, z: 0.8},
            'start');
    }

    routerMobile2Animation(router) {
        gsap.timeline({
            scrollTrigger: {
                trigger: '#mobile-model-additional',
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        })
            .fromTo(router.rotation,
                {x: Math.degToRad(40), y: Math.degToRad(-45), z: Math.degToRad(40)},
                {x: Math.degToRad(50), y: Math.degToRad(-90), z: Math.degToRad(50)},
            );
    }

    router1Animation(router) {
        const router1InitialRotation = {
            x: Math.degToRad(0),
            y: Math.degToRad(0),
            z: Math.degToRad(0),
        }
        const model = document.getElementById('model');

        setTimeout(() => {
            router.rotation.set(router1InitialRotation.x, router1InitialRotation.y, router1InitialRotation.z);
        });

        gsap.timeline({
            scrollTrigger: {
                trigger: '#section-landing',
                start: 'top top',
                end: '25% top',
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        })
            .add('start')
            .fromTo(router.rotation,
                {x: router1InitialRotation.x, y: router1InitialRotation.y, z: router1InitialRotation.z},
                {x: Math.degToRad(55), y: Math.degToRad(0), z: Math.degToRad(0)},
                'start'
            );

        gsap.timeline({
            immediateRender: false,
            scrollTrigger: {
                trigger: "#section-landing",
                start: "25% top",
                end: "bottom top",
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        })
            .add('start')
            .fromTo(router.rotation,
                {x: Math.degToRad(55), y: Math.degToRad(0), z: Math.degToRad(0)},
                {x: Math.degToRad(70), y: Math.degToRad(-55), z: Math.degToRad(70)},
                'start'
            )
            .fromTo(model, {xPercent: 0}, {xPercent: -25},
                'start')
            .fromTo(router.scale,
                {x: 1, y: 1, z: 1},
                {x: 0.8, y: 0.8, z: 0.8},
                'start');

        gsap.timeline({
            immediateRender: false,
            scrollTrigger: {
                trigger: "#section-second",
                start: "top top",
                end: "bottom top",
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        }).fromTo(router.rotation,
            {x: Math.degToRad(70), y: Math.degToRad(-55), z: Math.degToRad(70)},
            {x: Math.degToRad(0), y: Math.degToRad(0), z: Math.degToRad(0)},
        );
    }

    router2Animation(router) {
        gsap.timeline({
            scrollTrigger: {
                trigger: "#section-fourth",
                start: "-50% top",
                end: "150% top",
                scrub: true,
                toggleActions: 'play pause resume reset'
            }
        }).fromTo(router.rotation, {
            x: Math.degToRad(60), y: Math.degToRad(0), z: Math.degToRad(0)
        }, {
            x: Math.degToRad(80), y: Math.degToRad(0), z: Math.degToRad(0)
        });
    }

    checkIsMobileResolution() {
        return window.innerWidth < 992;
    }
}