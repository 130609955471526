import {Group, MeshStandardMaterial, TextureLoader} from 'three';
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

export default class Router extends Group {
    constructor(render) {

        super();

        this.render = render;
        this.name = 'router';
        this.loadObject();
    }
    
    loadTextures() {

        const textureLoader = new TextureLoader();

        if (window.location.pathname == "/w" || window.location.pathname == "/plw") {
            this.textureBaseColor = textureLoader.load('/assets/textures/untitled_Texture_BaseColor.jpg');
        }
        else
        {
            this.textureBaseColor = textureLoader.load('/assets/textures/untitled_Texture_BaseColor-dark.jpg');
        }

        this.textureEmissive = textureLoader.load('/assets/textures/untitled_Texture_Emissive.jpg');
        this.textureBump = textureLoader.load('/assets/textures/untitled_Texture_Height.jpg');
        this.textureMetalness = textureLoader.load('/assets/textures/untitled_Texture_Metallic.jpg');
        this.textureNormal = textureLoader.load('/assets/textures/untitled_Texture_Normal.jpg');
        this.textureRoughness = textureLoader.load('/assets/textures/untitled_Texture_Roughness.jpg');
        //this.textureAoMap = textureLoader.load('/assets/textures/untitled_Texture_AoMap.jpg');

        this.textureBaseColor.anisotropy = this.render.capabilities.getMaxAnisotropy();
    }

    loadObject() {
        const objLoader = new OBJLoader();
        this.loadTextures();

        objLoader.load('assets/models/router.obj', (object) => {

            const materialBody = new MeshStandardMaterial({
                map: this.textureBaseColor,
                //aoMap: this.textureAoMap,
                bumpMap: this.textureBump,
                //emissiveMap: this.textureEmissive,
                normalMap: this.textureNormal,
                roughnessMap: this.textureRoughness,
                metalnessMap: this.textureMetalness,
                //fog: true,
                //color: 0xffffffff,
                reflectivity: 0.5,
                transparent: false,
            });

            object.children[0].material = materialBody;
            object.children[1].material = materialBody;
            object.children[2].material = materialBody;
            object.rotation.set(Math.degToRad(0), Math.degToRad(180), Math.degToRad(0));


            

            this.add(object);
            this.dispatchModelLoaded();
        }, (progress) => this.onModelProgress(progress));
    }

    onModelProgress(progress) {
        this.dispatchEventProgressEvent(progress);
    }

    dispatchModelLoaded() {
        document.dispatchEvent(window.routerLoadedEvent);
    }

    dispatchEventProgressEvent(detail) {
        const event = new CustomEvent('LoadingProgressEvent', {detail});
        document.dispatchEvent(event);
    }
}
