import { Group, SpotLight, PointLight, AmbientLight, HemisphereLight, Color } from 'three';

export default class BasicLights extends Group {
  constructor(...args) {
    super(...args);

    const point = new PointLight(0x8871B6, 1, 8, 9);
    const dir = new SpotLight(0x8871B6, 0.8, 7, 0.8, 1, 1);
    //const dir2 = new SpotLight(0x8871B6, 0.8, 7, 0.8, 1, 1);
    const ambi = new AmbientLight( 0xffffff , 0.66);
    const hemi = new HemisphereLight( 0x8871B6, 0x080820, 2.15 )

    //const hemi2 = new HemisphereLight( 0x800080, 0x800080, 1.5 )

    dir.position.set(5, 1, 2);
    dir.target.position.set(0,0,0);

    //dir2.position.set(2, 6, 3);
    //dir2.target.position.set(0,0,0);

    point.position.set(0, 1, 5);
    point.intensity = 1.5;

    this.add(point);
    this.add(ambi);
    this.add(hemi);
    this.add(dir);
    //this.add(dir2);

    //this.add(hemi2);
  }
}
