import { Group } from 'three';
import BasicLights from './Lights.js';
import Router from "./Router/Router";

export default class SeedScene extends Group {
  constructor(render) {
    super();

    const router = new Router(render);
    const lights = new BasicLights();

    this.add(router, lights);
  }
}
